@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*,*::after,*::before{
    box-sizing: border-box;
}

.grid-center{
    display: grid;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-content: center;
    gap: 1em;
}

.grid-center-loading{
    display: grid;
    padding: 5em;
    justify-content: center;
    align-content: center;
    gap: 1em;
}

.datePicker{
    display:block;
    margin-bottom: 1em;
    width: 100%;
    padding: 1em;
    font-family: "Roboto", sans-serif;
    border-color: rgb(118, 118, 118);
}

.datePicker select{
    border-color: #1976d2;
}

.body{
    font-family: 'Roboto', sans-serif;
}

@media print {
    .datePicker{
        display: none;
    }

    body{
        font-size: 12px;
    }

    .main{
        display: block !important;
    }
}

/* Bill Css */

.main{
    display: none;
    text-align: left;
    border-collapse: collapse;
    font-family: 'Roboto', sans-serif;

}

.main td{
    border-style: solid;
    border-width: 1px;
    height: 2em;
    padding: 0.25em 0.5em;
    
}

.center{
    text-align: center;
}

.bold{
    font-weight: 600;
}

.h > td{
    height: 3.5em;
    vertical-align: top;
}

.und{
    text-decoration: underline;
}

.double{
    border-bottom-style: double !important;
}

.h2{
    height: 7em;
}
